import { call, put, select, takeLatest } from 'redux-saga/effects';
import download from 'redux/utils/download';
import { openErrorGlobalDialog } from 'redux/modules/GlobalDialog/actions';
import { FeatureFlagsApi } from 'app/redux/modules/FeatureFlags/api';
import { FLAGS } from 'app/redux/modules/FeatureFlags/constants';
import Sentry from 'app/lib/analytics/sentry';
import ERRORS from 'app/lib/analytics/sentry/errors';
import { ServiceSelectionApi } from 'redux/modules/ServiceSelection/api';
import { OrderSummaryApi } from 'app/redux/modules/OrderSummary/api';
import { postApplicationCompleteRequest } from 'redux/modules/App/actions';
import { ApplicationApi } from 'app/redux/modules/App/api';
import { postApplicationCreditRisk } from '../service';
import { downloadContract } from '../service/telecomContracts';
import * as types from '../types';
import * as actions from '../actions';

export function* handleConfirmOrder() {
  const preventOrganicPrepaid = yield select((state) =>
    FeatureFlagsApi.getFlag(state, FLAGS.ORGANIC_PREPAID_DENIED)
  );

  const isOrganic = yield select(ApplicationApi.isOrganicApplication);
  const submitRestrictions = yield select(
    ServiceSelectionApi.getIsSubmissionRestricted
  );

  if (preventOrganicPrepaid) {
    const hasPrepayment = yield select(OrderSummaryApi.getHasEnergyPrepayment);

    if (isOrganic && hasPrepayment) {
      return yield put(actions.setSubmitDeniedDialog(true));
    }
  }

  if (submitRestrictions) {
    return yield put(actions.setSubmitDeniedDialog(true));
  }

  return yield put(actions.creditRiskRequest());
}

/**
 * post credit-check and payment
 */
function* handleOrderConfirmed(paymentRequired) {
  /*
  need to call application complete without triggering payment verification
  and then call the get account number to move to the thank you page
  */
  if (!paymentRequired) {
    return yield put(postApplicationCompleteRequest());
  }

  // Consolidation Form
  return yield put(actions.yourDetailsSubmissionEnded());
}

export function* handlePostApplicationCreditRiskRequest() {
  try {
    const { data } = yield call(postApplicationCreditRisk);
    yield put(actions.postCreditRiskRequestSuccess(data.depositPrice));
    if (data?.depositPrice?.value === 0) {
      yield put(actions.confirmOrderSuccess(false));
    } else {
      yield put(actions.confirmOrderSuccess(true));
    }
  } catch (error) {
    Sentry.log(error, ERRORS.GET_CREDIT_RISK);
    // Consolidation Form
    yield put(actions.yourDetailsSubmissionEnded());
    yield put(actions.postCreditRiskRequestFailure(error));
    const errorMessage = `We encountered an error while retrieving your application summary. ${error}`;
    return yield put(openErrorGlobalDialog(errorMessage));
  }
}

function* getContractUrl() {
  try {
    const {
      data: { url: contractUrl },
    } = yield call(downloadContract);
    yield put(actions.contractDownloadSuccess(contractUrl));
    download(contractUrl);
  } catch (err) {
    Sentry.log(err, ERRORS.EECC_REQUEST_DOWNLOAD);
    const responseData = err?.response?.data || { message: 'Request error' };
    const { message, type } = responseData;
    yield put(actions.contractDownloadFailure(message || type));
    yield put(openErrorGlobalDialog(message || type));
  }
}

export function* combinedSagas() {
  yield takeLatest(types.ORDER_CONFIRM_REQUEST, handleConfirmOrder);

  yield takeLatest(
    types.ORDER_CONFIRMED,
    handleOrderConfirmed.bind(null, true)
  );
  yield takeLatest(
    types.ORDER_CONFIRMED_NO_PAYMENT,
    handleOrderConfirmed.bind(null, false)
  );

  yield takeLatest(
    types.POST_APPLICATION_CREDIT_RISK_REQUEST,
    handlePostApplicationCreditRiskRequest
  );
  yield takeLatest(types.EECC_CONTRACT_DOWNLOAD_REQUEST, getContractUrl);
}
