import { sumPrices } from 'app/redux/utils/priceUtils';
import { PRODUCT_INSURANCE } from 'app/redux/modules/Bundles/constants';
import { compact } from 'lodash';

const transformInsurance = ({ boiler, incomeProtector }) => {
  if (!boiler && !incomeProtector) return;

  const mappedBoiler = boiler ? { price: boiler.quote.price } : undefined;
  const mappedIncomeProtector = incomeProtector
    ? { price: incomeProtector.price }
    : undefined;

  const prices = compact([mappedBoiler?.price, mappedIncomeProtector?.price]);

  return {
    id: PRODUCT_INSURANCE,
    price: sumPrices(prices),
    boiler: mappedBoiler,
    incomeProtector: mappedIncomeProtector,
  };
};

export default transformInsurance;
