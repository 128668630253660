import { formatPrice } from 'app/redux/utils/formatter';
import { PRODUCT_BROADBAND } from 'app/redux/modules/Bundles/constants';
import { isNumber } from 'lodash';

const getLineActivationString = (cost, period = 1) => {
  const total = formatPrice(cost);
  const ppmValue = period > 0 ? cost.value / period : cost.value;

  const ppmFormatted = {
    ...cost,
    value: ppmValue,
  };

  return period > 1
    ? `${total} (${formatPrice(ppmFormatted)} per month for ${period} months)`
    : total;
};

export const transformHomephone = (homephone) => {
  if (!homephone) return undefined;
  const nonFreeFeatures = homephone.features.find(({ cost }) => cost);
  const name = nonFreeFeatures
    ? `${nonFreeFeatures?.name} (${formatPrice(nonFreeFeatures.cost)})`
    : '';

  let lineActivation = null;

  // valid case, there is no activation cost if customer has an existing landline
  if (homephone.activationPrice) {
    lineActivation = getLineActivationString(
      homephone.activationPrice,
      homephone.lineActivation?.paymentOption.spreadMonths
    );
  }

  return {
    name,
    number: homephone?.CLI?.number,
    lineActivation,
  };
};

function getTotalMonthlyPrices(broadband, homephone) {
  let rawMonthlyPrice = broadband.totalMonthlyCost.value;
  let rawPromoMonthlyPrice;

  if (homephone) {
    rawMonthlyPrice += homephone.totalMonthlyCost.value;
  }

  if (broadband?.promoMonthlyCost?.cost) {
    rawPromoMonthlyPrice = broadband.promoMonthlyCost.cost.value;
    if (homephone) {
      rawPromoMonthlyPrice +=
        homephone?.promoMonthlyCost?.cost?.value ??
        homephone.totalMonthlyCost.value;
    }
  }

  return {
    price: {
      ...broadband.totalMonthlyCost,
      value: rawMonthlyPrice,
    },
    promoPrice: isNumber(rawPromoMonthlyPrice)
      ? {
          ...broadband.totalMonthlyCost,
          value: rawPromoMonthlyPrice,
        }
      : null,
  };
}

const transformBroadband = (
  broadband,
  homephone,
  breakdownBroadband,
  breakdownHomephone
) => {
  if (!broadband) return;
  const { tariff } = broadband;

  const downloadSpeed = tariff.download.min
    ? `${tariff.download.min} - ${tariff.download.max} (At Least ${tariff.download.atLeast})`
    : `Up to ${tariff.download.max}`;

  const uploadSpeed = `Up to ${tariff.upload.max}`;

  const { price, promoPrice } = getTotalMonthlyPrices(
    breakdownBroadband,
    breakdownHomephone
  );

  return {
    id: PRODUCT_BROADBAND,
    broadband: {
      name: tariff.tariffLabel,
      price: breakdownBroadband.costForComparison,
      downloadSpeed,
      uploadSpeed,
    },
    homephone: breakdownHomephone && {
      ...transformHomephone(homephone),
      price: breakdownHomephone?.totalMonthlyCost,
    },
    price,
    discountedPrice: promoPrice,
    oneTimeCost: breakdownBroadband.totalInitialCost || null,
  };
};

export default transformBroadband;
