import { formatNumber } from 'redux/utils/formatter.js';
import { sumPrices } from 'app/redux/utils/priceUtils';
import { PRODUCT_ENERGY } from 'app/redux/modules/Bundles/constants';
import { compact } from 'lodash';
import { TARIFF_LABELS } from 'app/redux/modules/Energy/Quote/constants';

const getDiscountDesc = (tariff, dualFuel) => {
  switch (tariff) {
    case TARIFF_LABELS.DOUBLE_GOLD:
    case TARIFF_LABELS.DOUBLE_GOLD_FIXED:
      return `£${dualFuel ? '50' : '25'} discount unlocked`;
    case TARIFF_LABELS.GOLD:
      return `£${dualFuel ? '20' : '10'} discount unlocked`;
    default:
      return undefined;
  }
};

const valueTariffPrice = (quote, otherQuotes) => {
  if (!getDiscountDesc(quote.tariff.name)) return undefined;
  const valueQuote = otherQuotes
    .filter(({ type }) => type === quote.type)
    .find(({ tariff }) => tariff.name === 'Value');
  return valueQuote ? mapPersonalProjection(valueQuote) : undefined;
};

const mapPersonalProjection = (quote) => ({
  ...quote.personalProjection,
  value: quote.personalProjection.value / 12,
});

const transformQuote = (quote) => {
  return {
    tariff: quote.tariff.name,
    annualConsumption: `${formatNumber(quote.annualConsumption)} kWh`,
    unitRate: `${quote.tariff.unitRate.toFixed(3)} pence per kWh`,
    price: mapPersonalProjection(quote),
    standingCharge: `${quote.tariff.standingCharge.toFixed(3)} pence per day`,
    type: quote.tariff.type,
  };
};

const transformEnergy = ({ electricity, gas }, quotesContainer) => {
  if (!electricity && !gas) return undefined;
  const { quotes } = quotesContainer;
  const mappedElectricity = electricity
    ? transformQuote(electricity.quote)
    : undefined;
  const mappedGas = gas ? transformQuote(gas.quote) : undefined;
  const prices = compact([mappedElectricity?.price, mappedGas?.price]);

  const dualFuel = !!(electricity && gas);
  const discountDesc = electricity
    ? getDiscountDesc(electricity.quote.tariff.name, dualFuel)
    : getDiscountDesc(gas.quote.tariff.name, dualFuel);

  const valueTariffPrices = compact([
    electricity ? valueTariffPrice(electricity.quote, quotes) : undefined,
    gas ? valueTariffPrice(gas.quote, quotes) : undefined,
  ]);

  return {
    id: PRODUCT_ENERGY,
    price:
      valueTariffPrices.length > 0
        ? sumPrices(valueTariffPrices)
        : sumPrices(prices),
    electricity: mappedElectricity,
    gas: mappedGas,
    discountedPrice:
      valueTariffPrices.length > 0 ? sumPrices(prices) : undefined,
    discountDesc,
  };
};

export default transformEnergy;
