import { formatPrice } from 'app/redux/utils/formatter';
import { images } from '@utilitywarehouse/partner-ui-assets';

export const STORE_NAME = 'OrderConfirmation';
export const TERMS = {
  CASHBACK: 'CASHBACK',
  CASHBACK_POST_ED: 'CASHBACK_POST_ED',
  GENERIC: 'GENERIC',
  ENERGY_FIXED: 'ENERGY_FIXED',
  ENERGY_PREPAY: 'ENERGY_PREPAY',
};

export const PREPAYMENT = 'Prepayment';
export const PRODUCT_BOILER_INSURANCE = 'boilerInsurance';
export const PRODUCT_BILL_PROTECTOR = 'billProtector';
export const PRODUCT_INCOME_PROTECTOR = 'incomeProtector';
export const SERVICES = [
  'homephone',
  'broadband',
  'mobiles',
  'electricity',
  'gas',
  'cashback',
  'daffodil',
  'boilerInsurance',
  'billProtector',
];

export const NON_CORE_SERVICES = ['billProtector', 'cashback', 'homephone'];
export const INSURANCE_SERVICES = [
  'boilerInsurance',
  'incomeProtector',
  'billProtector',
];

export const ONEOFF_TYPES = {
  cashback: 'Cashback card application fee',
  broadband: 'router delivery charge',
};

export const ADD_ON_CASHBACK_CARD = 'cashback';
export const ADD_ON_BILL_PROTECTOR = 'billProtector';

export const addOns = [ADD_ON_CASHBACK_CARD, ADD_ON_BILL_PROTECTOR];

export const addOnMap = {
  [ADD_ON_CASHBACK_CARD]: 'Cashback Card',
  [ADD_ON_BILL_PROTECTOR]: 'Bill Protector',
};

export const addOnIconMap = {
  [ADD_ON_CASHBACK_CARD]: images.icons.productIcons.Cashback,
  [ADD_ON_BILL_PROTECTOR]: images.icons.productIcons.BillProtector,
};

export const defaultBillProtectorPrice = {
  value: 350,
  exponent: 2,
  currency: 'GBP',
};

export const defaultCashbackCardPrice = {
  value: 300,
  exponent: 2,
  currency: 'GBP',
};

export const addOnDescriptions = {
  [ADD_ON_CASHBACK_CARD]: () =>
    'For every pound you spend with your Cashback Card, you’ll earn as much as 10% cashback which comes directly off your UW bill.',
  [ADD_ON_BILL_PROTECTOR]: (price) =>
    `From just ${formatPrice(
      price
    )} a month, keep your UW bill covered in case you’re unable to work`,
};

export const priceOpts = { currency: 'GBP', exponent: 2 };
export const DEFAULT_SOCKET_INSTALLATION = 2400;

export const SUMMARY_REQ = 'summary';
export const SAVE_REQ = 'save';
export const SHARE_REQ = 'share';
export const YOUR_DETAILS_SUBMIT_REQ = 'yourDetails';

export const FIFTY_POUNDS_OFF_MINIMAL_SERVICES_COUNT = 3;
