import { difference, get, isEmpty } from 'lodash';

import { REQUEST_STATUS_STORE_KEY } from 'app/constants/requestStatus';
import {
  getBoostBenefit,
  getIsPrepayment,
  isRestrictedSelection,
  getFixedQuotesSummary,
} from 'app/redux/modules/Bundles/api';
import {
  PRODUCT_ENERGY,
  PRODUCT_BROADBAND,
  PRODUCT_MOBILE,
  PRODUCT_INSURANCE,
  productList,
} from 'redux/modules/Bundles/constants';
import EligibilityServiceApi from 'redux/modules/Eligibility/api';
import { SERVICE_INSURANCE } from 'redux/modules/ServiceSelection/services';
import { buildSummarySavings } from 'redux/modules/Shared/savingsBenefitBuilder';

import {
  NON_CORE_SERVICES,
  INSURANCE_SERVICES,
  STORE_NAME,
  PREPAYMENT,
} from './constants';
import {
  transformProducts,
  getRawTotals,
  transformAddOns,
} from './presentation';

export class OrderSummaryApi {
  static getDeposits(state) {
    return state.get(STORE_NAME).toJS().application.deposits;
  }
  static getPaymentAmount(state) {
    return state.get(STORE_NAME).toJS().paymentAmount;
  }
  static hasContactEmail(state) {
    const accountHolders = state.get(STORE_NAME).toJS().application
      .accountHolders;
    if (!accountHolders) {
      return false;
    }
    const secondaryEmail = accountHolders.find(
      (holder) =>
        holder.contact && holder.contact.trustedSecondaryEmail === true
    );
    const email = accountHolders.find(
      (holder) => holder.contact && holder.contact.email
    );
    return !!email || !!secondaryEmail;
  }

  static getCreditCheckRequired(state) {
    return state.getIn([STORE_NAME, 'creditCheckRequired']);
  }

  static getApplication(state) {
    return state.getIn([STORE_NAME, 'application']).toJS();
  }

  static getSnapshotId(state) {
    return state.getIn([STORE_NAME, 'snapshotId']);
  }

  static getShareDialogOpen(state) {
    return state.getIn([STORE_NAME, 'shareDialogOpen']);
  }

  static getIsEnergyOnly(state) {
    const reqServices = state.getIn([
      STORE_NAME,
      'application',
      'products',
      '_requestedServices',
    ]);

    if (reqServices) {
      return reqServices
        .toJS()
        .filter((el) => !NON_CORE_SERVICES.includes(el))
        .every((service) => service === 'energy');
    }
  }

  static getHasBroadband(state) {
    const reqServices = state.getIn([
      STORE_NAME,
      'application',
      'products',
      '_requestedServices',
    ]);
    if (reqServices) {
      return reqServices.toJS().some((service) => service === 'broadband');
    }
  }

  static getHasHomePhoneAndBrodband(state) {
    const reqServices = state.getIn([
      STORE_NAME,
      'application',
      'products',
      '_requestedServices',
    ]);
    if (reqServices) {
      const homePhone = reqServices
        .toJS()
        .some((service) => service === 'homephone');
      const broadband = reqServices
        .toJS()
        .some((service) => service === 'broadband');
      if (homePhone && broadband) {
        return 'BROADBAND_AND_VOICE';
      }
      if (broadband) {
        return 'BROADBAND';
      }
    }
    return null;
  }

  static getHomePhoneNumber(state) {
    const reqServices = state
      .getIn([STORE_NAME, 'application', 'products'])
      .toJS();
    if (
      reqServices.homephone &&
      reqServices.homephone.CLI &&
      reqServices.homephone.CLI.number
    ) {
      return reqServices.homephone.CLI.number;
    }
    return null;
  }

  static getInsuranceServices(state) {
    const reqServices = state
      .getIn([STORE_NAME, 'application', 'products', '_requestedServices'])
      .toJS();
    return reqServices.filter((service) =>
      INSURANCE_SERVICES.includes(service)
    );
  }

  static getHasEnergyAndCoreService(state) {
    const reqServices = state.getIn([
      STORE_NAME,
      'application',
      'products',
      '_requestedServices',
    ]);

    if (reqServices) {
      let ineligibleServices = ['energy', ...NON_CORE_SERVICES];
      const isEligibleService = (service) =>
        !ineligibleServices.includes(service);
      return reqServices.toJS().filter(isEligibleService).length > 0;
    }
  }
  static isRestrictedSelection(state) {
    const reqServices = state.getIn([
      STORE_NAME,
      'application',
      'products',
      '_requestedServices',
    ]);

    if (reqServices) {
      return isRestrictedSelection(state, reqServices);
    }
  }

  static getProductIds(state) {
    let products = state.getIn([STORE_NAME, 'application', 'products']);
    if (products?.toJS) {
      products = products.toJS();
    }

    if (!products) return {};
    const productIds = [];
    if (products.incomeProtector || products.boilerInsurance) {
      productIds.push(PRODUCT_INSURANCE);
    }

    if (products.gas || products.electricity) {
      productIds.push(PRODUCT_ENERGY);
    }

    if (products.mobiles) {
      productIds.push(PRODUCT_MOBILE);
    }

    if (products.broadband) {
      productIds.push(PRODUCT_BROADBAND);
    }

    return productList.reduce((acc, id) => {
      return {
        ...acc,
        [id]: productIds.includes(id),
      };
    }, {});
  }

  static getTotals(state) {
    const application = OrderSummaryApi.getApplication(state);
    const deposits = get(application, 'deposits', null);

    const products = transformProducts(application);
    const addOns = transformAddOns(application.products, state);

    return getRawTotals(products, addOns, deposits);
  }

  static getExtraDiscounts(state) {
    const application = OrderSummaryApi.getApplication(state);

    return get(application, 'breakdown.discounts', null);
  }

  static getMonthlyExtraDiscounts(state) {
    const discounts = OrderSummaryApi.getExtraDiscounts(state);

    if (!discounts) {
      return null;
    }

    return discounts.map(({ amount }) => ({
      // FIXME: Currently API discounts field is returning only 4 services credit discount
      // https://linear.app/utilitywarehouse/issue/AQ-3180/22nd-april-4service-bundle-containing-energy-get-pound50annum-credit
      // once this changed ensure that API returns reason field as well
      reason: 'Bundle discount',
      amount: {
        ...amount,
        value: Math.floor(amount.value / 12),
      },
    }));
  }

  static getFourServicesDiscount(state) {
    const isEligible = EligibilityServiceApi.isEligibleForFourServiceDiscount(
      state
    );

    if (!isEligible) {
      return null;
    }

    return this.getMonthlyExtraDiscounts(state);
  }

  static getAggregatedTotals(state) {
    const { productTotals } = OrderSummaryApi.getTotals(state);
    const mappedApplicationServices = {
      gas: PRODUCT_ENERGY,
      electricity: PRODUCT_ENERGY,
      broadband: PRODUCT_BROADBAND,
      homephone: PRODUCT_BROADBAND,
      mobiles: PRODUCT_MOBILE,
      incomeProtector: PRODUCT_INSURANCE,
      boilerInsurance: PRODUCT_INSURANCE,
    };

    return Object.entries(productTotals).reduce((acc, [product, cost]) => {
      const mappedProduct = mappedApplicationServices[product] || product;
      return {
        ...acc,
        [mappedProduct]: acc[mappedProduct]
          ? acc[mappedProduct] + parseInt(cost, 10)
          : parseInt(cost, 10),
      };
    }, {});
  }

  static getDownloadRequestError(state) {
    return state.getIn([STORE_NAME, 'contracts', 'downloadError']);
  }

  static getTotalServicesCount(state) {
    const { products } = OrderSummaryApi.getApplication(state);
    const { mobiles, ...services } = products;

    let serviceCount = Object.keys(services).filter((s) => {
      const isValidKey = s !== '_requestedServices';
      const isCoreService = !NON_CORE_SERVICES.includes(s);

      return isValidKey && isCoreService;
    }).length;

    serviceCount += mobiles?.length || 0;

    return serviceCount;
  }

  static getHasEnergyPrepayment(state) {
    const products = state.getIn([STORE_NAME, 'application', 'products']);
    const electricity = products.get('electricity')?.toJS();
    const gas = products.get('gas')?.toJS();

    return (
      electricity?.quote?.tariff?.paymentMethod === PREPAYMENT ||
      gas?.quote?.tariff?.paymentMethod === PREPAYMENT
    );
  }

  static getHasApplicationLead(state) {
    const lead = state.getIn([STORE_NAME, 'application', 'lead']);
    if (lead?.verified && lead?.leadReferral) {
      const {
        referredByCustomerAccountId,
        referredByCustomerAccountNumber,
        referredByPartnerPositionId,
        referredByStaffAccountId,
      } = lead.leadReferral;

      return (
        !!(referredByCustomerAccountId && referredByCustomerAccountNumber) ||
        !!referredByPartnerPositionId ||
        !!referredByStaffAccountId
      );
    }

    return false;
  }

  static getApplicationLead(state) {
    return state.getIn([STORE_NAME, 'application', 'lead'], null)?.toJS();
  }

  static getBreakdown(state) {
    return state.getIn([STORE_NAME, 'application', 'breakdown'], null).toJS();
  }

  static getBreakdownProduct(state, product) {
    return state
      .getIn([STORE_NAME, 'application', 'breakdown', 'services'], null)
      ?.find((p) => p.get('name') === product)
      ?.toJS();
  }
  static hasProducts(state) {
    return (
      state
        .getIn([STORE_NAME, 'application', 'products', '_requestedServices'])
        ?.count() > 0
    );
  }

  static getMappedRequestedServices(state) {
    const services = state
      .getIn([STORE_NAME, 'application', 'products', '_requestedServices'])
      .toJS();

    const mappedServices = services
      .filter((service) => !NON_CORE_SERVICES.includes(service))
      .map((service) => {
        switch (service) {
          case 'incomeProtector':
          case 'boilerInsurance':
            return SERVICE_INSURANCE;
          default:
            return service;
        }
      });

    return [...new Set(mappedServices)];
  }

  /**
   * get the difference between the createdOn date, and the current timestamp,
   * formatted in seconds.
   */
  static getApplicationDuration(state) {
    const createdAt = state.getIn([STORE_NAME, 'application', 'createdOn']);
    const duration = Math.round(
      (Date.now() - new Date(createdAt).getTime()) / 1000
    );
    return duration;
  }

  static getMainAccountHolderName = (state) => {
    return state
      .getIn([STORE_NAME, 'application', 'accountHolders'])
      ?.find((a) => a.get('isMainAccountHolder') === true)
      .get('name')
      .toJS();
  };

  static getRequestStatus = (state) =>
    state.getIn([STORE_NAME, REQUEST_STATUS_STORE_KEY]).toJS();

  static getSubmitDialogOpen = (state) =>
    state.getIn([STORE_NAME, 'submitDeniedDialog']);

  static getSummaryBenefits = (state, selectedIds, serviceId) => {
    let benefits = buildSummarySavings(
      getFixedQuotesSummary(state),
      getIsPrepayment(state)
    ).find(
      (saving) =>
        isEmpty(difference(saving.serviceIds, selectedIds)) &&
        isEmpty(difference(selectedIds, saving.serviceIds))
    );

    if (!benefits) {
      return [];
    }

    const boostBenefit = getBoostBenefit(state, selectedIds.length);

    if (boostBenefit?.locked) {
      benefits.messages.locked.unshift({
        description: boostBenefit.locked,
        enabled: true,
        id: 'BOOST',
        unlockedBy: [
          PRODUCT_BROADBAND,
          PRODUCT_ENERGY,
          PRODUCT_INSURANCE,
          PRODUCT_MOBILE,
        ],
      });
    }

    return benefits.messages.locked.filter(({ unlockedBy }) =>
      unlockedBy.includes(serviceId)
    );
  };

  static prepareAnalyticsServiceQuantities = (state) => {
    let products = state.getIn([STORE_NAME, 'application', 'products']);

    if (!products) {
      return null;
    }

    if (products?.toJS) {
      products = products.toJS();
    }

    let numberOfCoreServices = 0;
    let numberOfMobileServices = 0;

    if (products.gas || products.electricity) {
      numberOfCoreServices++;
    }

    if (products.mobiles) {
      numberOfMobileServices++;
      numberOfCoreServices++;

      if (products.mobiles.length > 1) {
        numberOfMobileServices = products.mobiles.length;
        numberOfCoreServices++;
      }
    }

    if (products.broadband) {
      numberOfCoreServices++;
    }

    if (products.incomeProtector || products.boilerInsurance) {
      numberOfCoreServices++;
    }

    const hasMultipleSimsSelected = numberOfMobileServices > 1;

    return {
      multiple_sims_selected: hasMultipleSimsSelected,
      number_of_mobile_services: numberOfMobileServices,
      number_of_core_services: numberOfCoreServices,
    };
  };

  static getAnalyticsSummaryCoreServices(state) {
    const selectedServices = this.getProductIds(state);
    const pricesRaw = this.getAggregatedTotals(state);
    const prices = Object.entries(pricesRaw).reduce(
      (formattedPrices, [product, val]) => {
        if (
          [
            PRODUCT_ENERGY,
            PRODUCT_BROADBAND,
            PRODUCT_MOBILE,
            PRODUCT_INSURANCE,
          ].includes(product)
        ) {
          formattedPrices[`${product}_price`] = val;
        }
        return formattedPrices;
      },
      {}
    );

    const quantities = this.prepareAnalyticsServiceQuantities(state);

    if (!quantities) {
      return null;
    }

    return {
      ...selectedServices,
      ...prices,
      ...quantities,
    };
  }

  static getIsTBYBOfferSelected(state) {
    const promo = state.getIn([
      STORE_NAME,
      'application',
      'products',
      'broadband',
      'tariff',
      'contract',
      'promo',
    ]);
    if (!promo || promo.get('id') !== 'TBYB') {
      return false;
    }
    return true;
  }
}
