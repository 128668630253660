import Immutable from 'immutable';
import {
  cancelRequestState,
  createRequestStateObject,
  failedRequestState,
  fetchingRequestState,
  successRequestState,
} from 'redux/utils/isRequestInProgress';

import * as types from './types';
import {
  YOUR_DETAILS_SUBMIT_REQ,
  SAVE_REQ,
  SHARE_REQ,
  SUMMARY_REQ,
} from './constants';

const initialState = Immutable.fromJS({
  creditCheckDialogOpen: false,
  creditCheckRequired: false,
  creditCheckPerformed: false,
  creditCheckAccepted: true,
  terms: {},
  marketing: false,
  application: {},
  fixedQuotesSummary: null,
  isPrepayment: false,
  removeProductModal: {
    open: false,
    changes: null,
    energyQuotes: null,
    productToRemove: null,
    message: null,
  },
  tbybModal: {
    open: false,
    productToRemove: null,
  },
  snapshotId: null,
  shareDialogOpen: false,
  shareEmail: '',
  removeProductError: {
    open: false,
    message: null,
  },
  ...createRequestStateObject([
    SUMMARY_REQ,
    SAVE_REQ,
    SHARE_REQ,
    YOUR_DETAILS_SUBMIT_REQ,
  ]),
  contracts: {
    downloadLoading: false,
    downloadError: null,
    emailLoading: false,
    emailError: null,
  },
  submitDeniedDialog: false,
});

export default function OrderConfirmationReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_APPLICATION_SUMMARY_REQUEST:
      return fetchingRequestState(state, SUMMARY_REQ);

    case types.GET_APPLICATION_SUMMARY_FAILURE:
      return failedRequestState(state, SUMMARY_REQ).mergeDeep({
        error: action.error,
        creditCheckDialogOpen: false,
      });

    case types.ORDER_CONFIRMED_NO_PAYMENT:
      return state.mergeDeep({
        payment: false,
      });
    case types.ORDER_CONFIRMED:
      return state.mergeDeep({ payment: true });
    case types.GET_APPLICATION_SUMMARY_SUCCESS:
      return successRequestState(state, SUMMARY_REQ).merge({
        application: action.application,
      });

    case types.GET_APPLICATION_SUCCESS:
      return successRequestState(state, SUMMARY_REQ).merge({
        application: action.application,
      });

    case types.SET_OPT_OUT_MARKETING:
      return state.merge({
        marketing: action.checked,
      });

    case types.SET_FIXED_QUOTES_SUMMARY:
      return state.merge({
        fixedQuotesSummary: action.fixedQuotesSummary,
      });

    case types.SET_IS_PREPAYMENT:
      return state.merge({
        isPrepayment: action.isPrepayment,
      });

    case types.SET_REMOVE_MODAL:
      return state.merge({
        removeProductModal: {
          open: action.open,
          changes: action.changes,
          energyQuotes: action.energyQuotes,
          productToRemove: action.product,
          message: action.message,
        },
      });

    case types.REMOVE_PRODUCT_SUCCESS:
      return state.merge({
        application: action.application,
      });

    case types.ACCEPT_ENERGY_QUOTE_SUCCESS:
      return state.merge({
        removeProductModal: {
          open: false,
          changes: null,
          energyQuotes: null,
          productToRemove: null,
          message: '',
        },
      });

    case types.ACCEPT_ENERGY_QUOTE_FAILURE:
      return state.merge({
        removeProductModal: {
          open: false,
          changes: null,
          energyQuotes: null,
          productToRemove: null,
          message: '',
        },
      });

    case types.SET_TBYB_MODAL:
      return state.merge({
        tbybModal: {
          open: action.open,
          productToRemove: action.product,
        },
      });

    case types.POST_APPLICATION_CREDIT_RISK_SUCCESS:
      return state.merge({
        paymentAmount: action.paymentAmount,
      });

    case types.POST_APPLICATION_SAVE_CANCEL:
      return cancelRequestState(state, SAVE_REQ);

    case types.POST_APPLICATION_SAVE_REQUEST:
      return fetchingRequestState(state, SAVE_REQ);

    case types.POST_APPLICATION_SAVE_FAILURE:
      return failedRequestState(state, SAVE_REQ).mergeDeep({
        error: action.error,
      });

    case types.POST_APPLICATION_SAVE_SUCCESS:
      return successRequestState(state, SAVE_REQ);

    case types.POST_APPLICATION_SHARE_CANCEL:
      return cancelRequestState(state, SHARE_REQ);

    case types.POST_APPLICATION_SHARE_REQUEST:
      return fetchingRequestState(state, SHARE_REQ);

    case types.POST_APPLICATION_SHARE_FAILURE:
      return failedRequestState(state, SHARE_REQ).mergeDeep({
        error: action.error,
      });

    case types.POST_APPLICATION_SHARE_SUCCESS:
      return successRequestState(state, SHARE_REQ);

    case types.SET_SNAPSHOT_ID:
      return state.set('snapshotId', action.id || null);

    case types.RESET_SNAPSHOT_ID:
      return state.set('snapshotId', null);
    case types.SET_SHARE_DIALOG:
      return state.set('shareDialogOpen', action.open);
    case types.SET_SHARE_EMAIL:
      return state.set('shareEmail', action.email);
    case types.SET_REMOVE_PRODUCT_ERROR:
      return state.merge({
        removeProductError: {
          open: action.open,
          message: action.message,
        },
      });

    case types.EECC_CONTRACT_DOWNLOAD_REQUEST:
      return state.mergeDeep({
        contracts: {
          downloadError: null,
          downloadLoading: true,
        },
      });
    case types.EECC_CONTRACT_DOWNLOAD_SUCCESS:
      return state.setIn(['contracts', 'dowloadLoading'], false, action.url);

    case types.EECC_CONTRACT_DOWNLOAD_FAILURE:
      return state.mergeDeep({
        contracts: {
          downloadError: action.error,
          downloadLoading: false,
        },
      });

    case types.SET_SUBMIT_DENIED_DIALOG:
      return state.set('submitDeniedDialog', action.open);

    case types.YOUR_DETAILS_FORM_SUBMISSION_STARTED:
      return fetchingRequestState(state, YOUR_DETAILS_SUBMIT_REQ);

    case types.YOUR_DETAILS_FORM_SUBMISSION_ENDED:
      return cancelRequestState(state, YOUR_DETAILS_SUBMIT_REQ);

    default:
      return state;
  }
}
