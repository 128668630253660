import { authenticatedDelete } from 'redux/utils/request';

export default function(product, dryRun = false) {
  let endpoint = `products/${product}`;

  if (dryRun) {
    endpoint = `${endpoint}/dry-run`;
  }

  return authenticatedDelete(endpoint);
}
