import { ADD_ON_BILL_PROTECTOR } from 'app/redux/modules/OrderSummary/constants';

const transformBillProtector = (billProtector) => {
  const price = billProtector.price;

  return {
    id: ADD_ON_BILL_PROTECTOR,
    price,
  };
};

export default transformBillProtector;
