import { connector } from 'redux/utils/connector';
import Save from 'app/components/modules/OrderSummary/ServicesTotal/Save';
import { ApplicationApi } from 'app/redux/modules/App/api';
import { OrderSummaryApi } from 'app/redux/modules/OrderSummary/api';
import { STORE_NAME } from '../constants';
import * as actions from '../actions';

const mapStateToProps = (state) => {
  const isSales = ApplicationApi.isSalesApplication(state);
  const application = OrderSummaryApi.getApplication(state);
  const isApplicationComplete =
    application.accountHolders && application.accountHolders[0].contact;
  const isApplicationReadOnly = ApplicationApi.getIsReadOnly(state);

  const {
    summary: summaryStatus,
    save: saveStatus,
    share: shareStatus,
  } = state.getIn([STORE_NAME, 'requestStatus']).toJS();

  return {
    isSales,
    isApplicationComplete,
    isApplicationReadOnly,
    partnerEmail: state.getIn([
      STORE_NAME,
      'application',
      'partnerRelationship',
      'partner',
      'email',
    ]),
    summaryStatus,
    saveStatus,
    shareStatus,
    shareDialogOpen: OrderSummaryApi.getShareDialogOpen(state),
  };
};

export default connector(STORE_NAME, mapStateToProps, {
  triggerSave: actions.postSaveRequest,
  triggerShare: actions.postShareRequest,
  resetSave: actions.cancelPostSaveRequest,
  resetShare: actions.cancelPostShareRequest,
  resetSnapshotId: actions.resetSnapshotId,
  setShareDialog: actions.setShareDialog,
})(Save);
