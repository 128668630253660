import { connector } from 'redux/utils/connector';
import applicationCheck from 'redux/modules/ApplicationCheck';
import { PRODUCT_MOBILE } from 'redux/modules/Bundles/constants';
import { STORE_NAME } from 'app/redux/modules/OrderSummary/constants';
import OrderSummary from 'app/components/modules/OrderSummary/OrderSummaryPage';
import { orderPreviewContinue } from 'app/redux/modules/OrderSummary/actions';
import { OrderSummaryApi } from 'app/redux/modules/OrderSummary/api';
import { ServiceSelectionApi } from 'redux/modules/ServiceSelection/api';

import { transformProducts } from '../presentation';

// TODO: Remove temporary solution
// https://linear.app/utilitywarehouse/issue/AQ-3168/block-multisim-mobile-only-deals-proceeding-past-ordersummary
const getIsMobileOnly = (products) => {
  const selectedProductKeys = Object.keys(products).filter((key) =>
    Boolean(products[key])
  );
  const isMobileOnly =
    selectedProductKeys.length === 1 &&
    selectedProductKeys.includes(PRODUCT_MOBILE);
  const hasMultipleSims = products.mobile && products.mobile.sims.length > 1;

  return isMobileOnly && hasMultipleSims;
};
// -------------------------------

export default connector(
  STORE_NAME,
  (state) => {
    // TODO: Remove temporary solution
    const application = OrderSummaryApi.getApplication(state);
    const products = transformProducts(application);
    const isMobileOnly = getIsMobileOnly(products);
    // -------------------------------

    const isRestrictedSelection = OrderSummaryApi.isRestrictedSelection(state);
    const hasSelectedServices = ServiceSelectionApi.hasSelectedServices(state);
    const hasProducts = OrderSummaryApi.hasProducts(state);

    return {
      nextDisabled:
        isRestrictedSelection ||
        !hasSelectedServices ||
        !hasProducts ||
        isMobileOnly,
      isMobileOnly,
    };
  },
  {
    handleNext: orderPreviewContinue,
  }
)(applicationCheck(OrderSummary));
