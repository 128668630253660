export const ORDER_CONFIRM_REQUEST = 'ORDER_CONFIRM_REQUEST';
export const ORDER_CONFIRMED = 'ORDER_CONFIRMED';
export const ORDER_CONFIRMED_NO_PAYMENT = 'ORDER_CONFIRMED_NO_PAYMENT';

export const GET_APPLICATION_SUMMARY_REQUEST =
  'GET_APPLICATION_SUMMARY_REQUEST';
export const GET_APPLICATION_SUMMARY_FAILURE =
  'GET_APPLICATION_SUMMARY_FAILURE';
export const GET_APPLICATION_SUMMARY_SUCCESS =
  'GET_APPLICATION_SUMMARY_SUCCESS';

export const POST_APPLICATION_CREDIT_RISK_REQUEST =
  'POST_APPLICATION_CREDIT_RISK_REQUEST';
export const POST_APPLICATION_CREDIT_RISK_FAILURE =
  'POST_APPLICATION_CREDIT_RISK_FAILURE';
export const POST_APPLICATION_CREDIT_RISK_SUCCESS =
  'POST_APPLICATION_CREDIT_RISK_SUCCESS';

export const SET_OPT_OUT_MARKETING = 'SET_OPT_OUT_MARKETING';
export const ORDER_PREVIEW_CONTINUE = 'ORDER_PREVIEW_CONTINUE';

export const GET_APPLICATION_SUCCESS = 'GET_APPLICATION_SUCCESS';

export const REMOVE_PRODUCT_REQUEST = 'REMOVE_PRODUCT_REQUEST';
export const REMOVE_PRODUCT_SUCCESS = 'REMOVE_PRODUCT_SUCCESS';
export const REMOVE_PRODUCT_FAILURE = 'REMOVE_PRODUCT_FAILURE';

export const SET_REMOVE_MODAL = 'SET_REMOVE_MODAL';
export const SMET_BOOKING_ELIGIBLE = 'SMET_BOOKING_ELIGIBLE';

export const POST_APPLICATION_SAVE_CANCEL = 'POST_APPLICATION_SAVE_CANCEL';
export const POST_APPLICATION_SAVE_REQUEST = 'POST_APPLICATION_SAVE_REQUEST';
export const POST_APPLICATION_SAVE_FAILURE = 'POST_APPLICATION_SAVE_FAILURE';
export const POST_APPLICATION_SAVE_SUCCESS = 'POST_APPLICATION_SAVE_SUCCESS';

export const POST_APPLICATION_SHARE_CANCEL = 'POST_APPLICATION_SHARE_CANCEL';
export const POST_APPLICATION_SHARE_REQUEST = 'POST_APPLICATION_SHARE_REQUEST';
export const POST_APPLICATION_SHARE_FAILURE = 'POST_APPLICATION_SHARE_FAILURE';
export const POST_APPLICATION_SHARE_SUCCESS = 'POST_APPLICATION_SHARE_SUCCESS';

export const SET_SNAPSHOT_ID = 'SET_SNAPSHOT_ID';
export const RESET_SNAPSHOT_ID = 'RESET_SNAPSHOT_ID';
export const SET_SHARE_DIALOG = 'SET_SHARE_DIALOG';
export const SET_SHARE_EMAIL = 'SET_SHARE_EMAIL';

export const SET_REMOVE_PRODUCT_ERROR = 'SET_REMOVE_PRODUCT_ERROR';

export const EECC_CONTRACT_DOWNLOAD_REQUEST = 'EECC_CONTRACT_DOWNLOAD_REQUEST';
export const EECC_CONTRACT_DOWNLOAD_SUCCESS = 'EECC_CONTRACT_DOWNLOAD_SUCCESS';
export const EECC_CONTRACT_DOWNLOAD_FAILURE = 'EECC_CONTRACT_DOWNLOAD_FAILURE';

export const SET_SUBMIT_DENIED_DIALOG = 'SET_SUBMIT_DENIED_DIALOG';

export const ACCEPT_ENERGY_QUOTE = 'ACCEPT_ENERGY_QUOTE';
export const ACCEPT_ENERGY_QUOTE_SUCCESS = 'ACCEPT_ENERGY_QUOTE_SUCCESS';
export const ACCEPT_ENERGY_QUOTE_FAILURE = 'ACCEPT_ENERGY_QUOTE_FAILURE';

export const SET_FIXED_QUOTES_SUMMARY = 'SET_FIXED_QUOTES_SUMMARY';
export const SET_IS_PREPAYMENT = 'SET_IS_PREPAYMENT';

export const YOUR_DETAILS_FORM_SUBMISSION_STARTED =
  'YOUR_DETAILS_FORM_SUBMISSION_STARTED';
export const YOUR_DETAILS_FORM_SUBMISSION_ENDED =
  'YOUR_DETAILS_FORM_SUBMISSION_ENDED';
export const CONFIRM_YOUR_DETAILS_FORM = 'CONFIRM_YOUR_DETAILS_FORM';

export const SET_TBYB_MODAL = 'SET_TBYB_MODAL';
