import sha256 from 'hash.js/lib/hash/sha/256';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { REDUX_STORE_VERSION } from 'app/redux';
import { addNotification } from 'app/redux/modules/Notifications/actions';
import { notificationKeys } from 'app/redux/modules/Notifications/library';
import { STORE_NAME as PARTNER_LOGIN_STORE_NAME } from 'redux/modules/PartnerLogin/constants';
import { STORE_NAME as SALES_LOGIN_STORE_NAME } from 'redux/modules/SalesLogin/constants';
import { STORE_NAME as APP_STORE_NAME } from 'redux/modules/App/constants';
import { STORE_NAME as REMOTE_STORE_NAME } from 'redux/modules/RemoteSession/v2/constants';
import { STORE_NAME as CASHBACK_STORE_NAME } from 'redux/modules/Cashback/constants';
import { STORE_NAME } from 'app/redux/modules/OrderSummary/constants';
import postApplicationSave from '../save/postApplicationSave';
import postApplicationShare from '../save/postApplicationShare';
import * as types from '../types';
import * as actions from '../actions';

function* handlePostApplicationShareRequest(action) {
  try {
    yield call(postApplicationShare, {
      id: action.snapshotId,
      email: action.email,
    });
    yield put(actions.postShareRequestSuccess());
    yield put(
      addNotification({
        message: notificationKeys.SUMMARY_QUOTE_SENT_SUCCESS,
        customMessage: `Quote sent to ${action.email}`,
      })
    );
  } catch (error) {
    yield put(actions.postShareRequestFailure(error));
  }
}

function* handlePostApplicationSaveRequest() {
  const createSnapshot = (state) => {
    // remove sensitive data from the snapshot
    let cleanState = state
      .setIn([PARTNER_LOGIN_STORE_NAME, 'partnerToken'], '')
      .setIn([PARTNER_LOGIN_STORE_NAME, 'refreshToken'], '')
      .setIn([PARTNER_LOGIN_STORE_NAME, 'supportingIdAccessToken'], '')
      .setIn([SALES_LOGIN_STORE_NAME, 'token'], '')
      // remove remote session related data
      .deleteIn([APP_STORE_NAME, 'remote'])
      .delete(CASHBACK_STORE_NAME)
      .delete(REMOTE_STORE_NAME);
    // remove failed request data to prevent nested JSON escaping
    if (cleanState.getIn([STORE_NAME, 'error', 'config', 'data'])) {
      cleanState = cleanState.setIn(
        [STORE_NAME, 'error', 'config', 'data'],
        ''
      );
    }
    return JSON.stringify(cleanState.toJS());
  };

  try {
    const snapshot = yield select(createSnapshot);
    const snapshotHash = sha256()
      .update(snapshot)
      .digest('hex');

    const res = yield call(postApplicationSave, {
      appState: {
        _version: REDUX_STORE_VERSION,
        redux: snapshot,
        checksum: snapshotHash,
      },
    });

    yield put(actions.setSnapshotId(res?.data?.id));
    yield put(actions.postSaveRequestSuccess());
    yield put(
      addNotification({
        message: notificationKeys.SUMMARY_APPLICATION_SAVE_SUCCESS,
      })
    );
  } catch (error) {
    yield put(actions.postSaveRequestFailure(error));
  }
}

export function* combinedSagas() {
  yield takeLatest(
    types.POST_APPLICATION_SAVE_REQUEST,
    handlePostApplicationSaveRequest
  );
  yield takeLatest(
    types.POST_APPLICATION_SHARE_REQUEST,
    handlePostApplicationShareRequest
  );
}
