import { fork } from 'redux-saga/effects';
import { combinedSagas as orderConfirmationSagas } from './orderConfirmation';
import { combinedSagas as orderSummarySagas } from './orderSummary';
import { combinedSagas as removeServiceSagas } from './removeService';
import { combinedSagas as saveShareSagas } from './saveShare';
import { saga as yourDetailsSaga } from './yourDetailsSubmit';

export function* combinedSagas() {
  yield fork(orderConfirmationSagas);
  yield fork(orderSummarySagas);
  yield fork(removeServiceSagas);
  yield fork(saveShareSagas);
  yield fork(yourDetailsSaga);
}
