import * as types from './types';

export const getSummaryRequest = (
  acceptedCreditCheck,
  isApplicationComplete
) => ({
  type: types.GET_APPLICATION_SUMMARY_REQUEST,
  acceptedCreditCheck,
  isApplicationComplete,
});

export const getSummaryRequestFailure = (error) => ({
  type: types.GET_APPLICATION_SUMMARY_FAILURE,
  error,
});

export const getSummaryRequestSuccess = (application) => ({
  type: types.GET_APPLICATION_SUMMARY_SUCCESS,
  application,
});

export const postCreditRiskRequest = () => ({
  type: types.POST_APPLICATION_CREDIT_RISK_REQUEST,
});

export const postCreditRiskRequestFailure = (error) => ({
  type: types.POST_APPLICATION_CREDIT_RISK_FAILURE,
  error,
});

export const postCreditRiskRequestSuccess = (paymentAmount) => ({
  type: types.POST_APPLICATION_CREDIT_RISK_SUCCESS,
  paymentAmount,
});

export const getApplicationSuccess = (application) => ({
  type: types.GET_APPLICATION_SUCCESS,
  application,
});

export const confirmOrder = () => ({
  type: types.ORDER_CONFIRM_REQUEST,
});

export const creditRiskRequest = () => ({
  type: types.POST_APPLICATION_CREDIT_RISK_REQUEST,
});

export const confirmOrderSuccess = (payment) => ({
  type: payment ? types.ORDER_CONFIRMED : types.ORDER_CONFIRMED_NO_PAYMENT,
});

export const orderPreviewContinue = () => ({
  type: types.ORDER_PREVIEW_CONTINUE,
});

export const removeProductRequest = (product, dryRun = true) => ({
  type: types.REMOVE_PRODUCT_REQUEST,
  product,
  dryRun,
});

export const removeProductSuccess = (application) => ({
  type: types.REMOVE_PRODUCT_SUCCESS,
  application,
});

export const removeProductFailure = (error) => ({
  type: types.REMOVE_PRODUCT_FAILURE,
  error,
});

export const setRemoveProductModal = (
  open,
  product,
  changes,
  message,
  energyQuotes
) => ({
  type: types.SET_REMOVE_MODAL,
  product,
  open,
  changes,
  energyQuotes,
  message,
});

export const setTbybModal = (open, product) => ({
  type: types.SET_TBYB_MODAL,
  open,
  product,
});

export const setFixedQuotesSummary = (fixedQuotesSummary) => ({
  type: types.SET_FIXED_QUOTES_SUMMARY,
  fixedQuotesSummary,
});

export const setIsPrepayment = (isPrepayment) => ({
  type: types.SET_IS_PREPAYMENT,
  isPrepayment,
});

export const postSaveRequest = () => ({
  type: types.POST_APPLICATION_SAVE_REQUEST,
});

export const postSaveRequestFailure = (error) => ({
  type: types.POST_APPLICATION_SAVE_FAILURE,
  error,
});

export const postSaveRequestSuccess = () => ({
  type: types.POST_APPLICATION_SAVE_SUCCESS,
});

export const cancelPostSaveRequest = () => ({
  type: types.POST_APPLICATION_SAVE_CANCEL,
});

export const postShareRequest = (snapshotId, email) => ({
  type: types.POST_APPLICATION_SHARE_REQUEST,
  snapshotId,
  email,
});

export const postShareRequestFailure = (error) => ({
  type: types.POST_APPLICATION_SHARE_FAILURE,
  error,
});

export const postShareRequestSuccess = () => ({
  type: types.POST_APPLICATION_SHARE_SUCCESS,
});

export const cancelPostShareRequest = () => ({
  type: types.POST_APPLICATION_SHARE_CANCEL,
});

export const setSnapshotId = (id) => ({
  type: types.SET_SNAPSHOT_ID,
  id,
});

export const resetSnapshotId = () => ({
  type: types.RESET_SNAPSHOT_ID,
});

export const setShareDialog = (open) => ({
  type: types.SET_SHARE_DIALOG,
  open,
});

export const setShareEmail = (email) => ({
  type: types.SET_SHARE_EMAIL,
  email,
});

export const setRemoveProductError = (open, message) => ({
  type: types.SET_REMOVE_PRODUCT_ERROR,
  open,
  message,
});

export const contractDownloadRequest = () => ({
  type: types.EECC_CONTRACT_DOWNLOAD_REQUEST,
});

export const contractDownloadSuccess = () => ({
  type: types.EECC_CONTRACT_DOWNLOAD_SUCCESS,
});

export const contractDownloadFailure = (error) => ({
  type: types.EECC_CONTRACT_DOWNLOAD_FAILURE,
  error,
});

export const setSubmitDeniedDialog = (open) => ({
  type: types.SET_SUBMIT_DENIED_DIALOG,
  open,
});

export const acceptEnergyQuotes = (quoteIds) => ({
  type: types.ACCEPT_ENERGY_QUOTE,
  quoteIds,
});

export const acceptEnergyQuotesSuccess = () => ({
  type: types.ACCEPT_ENERGY_QUOTE_SUCCESS,
});

export const acceptEnergyQuotesFailure = () => ({
  type: types.ACCEPT_ENERGY_QUOTE_FAILURE,
});

export const confirmYourDetailsForm = () => ({
  type: types.CONFIRM_YOUR_DETAILS_FORM,
});

export const yourDetailsSubmissionStarted = () => ({
  type: types.YOUR_DETAILS_FORM_SUBMISSION_STARTED,
});

export const yourDetailsSubmissionEnded = () => ({
  type: types.YOUR_DETAILS_FORM_SUBMISSION_ENDED,
});
