import { call, put, select, takeLatest } from 'redux-saga/effects';

import Sentry from 'app/lib/analytics/sentry';
import ERRORS from 'app/lib/analytics/sentry/errors';

import { ApplicationApi } from 'redux/modules/App/api';
import { FeatureFlagsApi } from 'redux/modules/FeatureFlags/api';
import { FLAGS } from 'redux/modules/FeatureFlags/constants';
import { openErrorGlobalDialog } from 'redux/modules/GlobalDialog/actions';
import { ServiceSelectionApi } from 'redux/modules/ServiceSelection/api';

import {
  creditRiskRequest,
  getSummaryRequestFailure,
  getSummaryRequestSuccess,
  setSubmitDeniedDialog,
  yourDetailsSubmissionEnded,
} from '../actions';
import { OrderSummaryApi } from '../api';

import { checkGoldEligibility } from './orderSummary';
import { CONFIRM_YOUR_DETAILS_FORM } from '../types';
import { getApplicationSummary } from '../service';

function* getSummary(confirmOrder) {
  yield call(checkGoldEligibility);
  try {
    let { data } = yield call(getApplicationSummary, false);
    yield put(getSummaryRequestSuccess(data));
    return yield call(confirmOrder);
  } catch (error) {
    Sentry.log(error, ERRORS.GET_APP_SUMMARY);
    yield put(getSummaryRequestFailure(error));
    const errorMessage = `We encountered an error while retrieving your application summary. ${error}`;
    yield put(openErrorGlobalDialog(errorMessage));
    return yield put(yourDetailsSubmissionEnded());
  }
}

function* handleOrderConfirmation() {
  const preventOrganicPrepaid = yield select((state) =>
    FeatureFlagsApi.getFlag(state, FLAGS.ORGANIC_PREPAID_DENIED)
  );

  const isOrganic = yield select(ApplicationApi.isOrganicApplication);
  const submitRestrictions = yield select(
    ServiceSelectionApi.getIsSubmissionRestricted
  );

  if (preventOrganicPrepaid) {
    const hasPrepayment = yield select(OrderSummaryApi.getHasEnergyPrepayment);

    if (isOrganic && hasPrepayment) {
      yield put(yourDetailsSubmissionEnded());
      return yield put(setSubmitDeniedDialog(true));
    }
  }

  if (submitRestrictions) {
    yield put(yourDetailsSubmissionEnded());
    return yield put(setSubmitDeniedDialog(true));
  }

  return yield put(creditRiskRequest());
}

function* getSummaryAndConfirmOrder() {
  yield call(getSummary, handleOrderConfirmation);
}

export function* saga() {
  yield takeLatest(CONFIRM_YOUR_DETAILS_FORM, getSummaryAndConfirmOrder);
}
