import capitalize from 'lodash/capitalize';
import { copyMapper } from 'redux/utils/copyMapper';
import { getMobileTariffPrice } from 'redux/modules/Mobile/lib';
import { sumPrices } from 'app/redux/utils/priceUtils';
import { PRODUCT_MOBILE } from 'app/redux/modules/Bundles/constants';

const transformMobile = (mobile, i, mobiles) => {
  const tariff = mobile.tariff;
  const price = getMobileTariffPrice(tariff, i, mobiles);
  const dataAllowance = isNaN(tariff.data)
    ? capitalize(tariff.data)
    : `${tariff.data} ${tariff.dataUnits}`;

  return {
    id: i,
    tariff: tariff.name || copyMapper(`mobile.tariff.label.${tariff.label}`),
    allowance: `${dataAllowance} data`,
    price,
  };
};

const transformMobiles = (mobiles) => {
  const sims = mobiles.map(transformMobile);

  return {
    id: PRODUCT_MOBILE,
    sims,
    price: sumPrices(sims.map(({ price }) => price)),
  };
};

export default transformMobiles;
