import { nonAuthenticatedRequest, METHOD } from 'redux/utils/request';
import { getCurrentToken } from 'redux/utils/storeHelper';

// NOTE: current utils doesn't provide authenticated request from api root
export default ({ id, email }) => {
  const url = `snapshot/${id}/send`;
  const headers = { authentication: getCurrentToken() };
  const data = { recipient: email };
  return nonAuthenticatedRequest(METHOD.POST, url, data, headers);
};
