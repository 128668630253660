import { call, put, select, takeLatest } from 'redux-saga/effects';
import { openErrorGlobalDialog } from 'redux/modules/GlobalDialog/actions';
import Sentry from 'app/lib/analytics/sentry';
import ERRORS from 'app/lib/analytics/sentry/errors';
import hasTariffChanged, {
  dependantServiceRemoved,
} from 'redux/utils/hasTariffChanged';

import { EVENTS } from 'app/lib/analytics/constants';
import analyticsSelectors from 'app/lib/analytics/AnalyticsProvider/selectors';
import { trackEvent } from 'app/redux/modules/Analytics/actions';
import { formatProductName } from 'app/lib/analytics/AnalyticsProvider/utils';

import { addNotification } from 'app/redux/modules/Notifications/actions';
import { notificationKeys } from 'app/redux/modules/Notifications/library';
import {
  SERVICE_ENERGY,
  SERVICE_MOBILE,
} from 'redux/modules/ServiceSelection/services';
import { removeService } from 'redux/modules/ServiceSelection/actions';
import { removeCashbackCard } from 'redux/modules/Cashback/actions';
import { deleteProduct } from '../service';
import * as types from '../types';
import * as actions from '../actions';
import { formatEnergyQuotes } from './orderSummary';

// worth splitting out into distinct functions, since
// different services require different approaches
//
// also includes defunct features such as homeaudit
export function* handleRemoveProduct(action) {
  const { product, dryRun } = action;

  // mainly to handle mobile having a different name, which
  // is derived from the application (as a list of "mobiles") vs. a
  // service name ("mobile")
  const mapProductToService = (product) => {
    if (product === 'mobiles') return SERVICE_MOBILE;
    return product;
  };
  try {
    if (dryRun) {
      const {
        data: { changes, message, energyQuotes },
      } = yield call(deleteProduct, product, dryRun);
      if (message) {
        yield put(actions.setRemoveProductError(true, message));
      } else if (hasTariffChanged(product, changes.products)) {
        yield put(
          actions.setRemoveProductModal(
            true,
            product,
            changes,
            null,
            formatEnergyQuotes(energyQuotes)
          )
        );
      } else {
        if (
          changes.products.to._requestedServices?.length === 1 &&
          changes.products.to._requestedServices[0] === 'broadband'
        ) {
          yield put(actions.setTbybModal(true, product));
        } else {
          yield put(actions.setRemoveProductModal(true, product));
        }
      }
    } else {
      const result = yield call(deleteProduct, product, false);
      if (result.data.message) {
        yield put(actions.setRemoveProductError(true, result.data.message));
        return;
      }
      const resultTo = result.data.changes.products.to;
      const resultFrom = result.data.changes.products.from;
      const serviceDiff = dependantServiceRemoved(
        product,
        resultFrom,
        resultTo
      );

      if (product === 'cashback') {
        yield put(removeCashbackCard());
      } else if (product === 'gas' || product === 'electricity') {
        // this can be consolidated into just removing "energy", since
        // the services are no longer split on the summary page
        const hasGas = Object.keys(resultTo).includes('gas');
        const hasElectricity = Object.keys(resultTo).includes('electricity');
        if (!(hasGas || hasElectricity))
          yield put(removeService(SERVICE_ENERGY));
      } else {
        // call removeService on removed service as well as
        // all the dependant services to prevent issues when
        // navigating back through the application
        yield put(removeService(mapProductToService(product)));
      }
      // since you can only yield in a generator function
      // use a standard for loop here
      for (let i = 0; i < serviceDiff.length; i += 1) {
        yield put(removeService(mapProductToService(serviceDiff[i])));
      }

      const removeMessage = `Removed ${product}${
        serviceDiff.length ? ` + ${serviceDiff.length} more` : ''
      }`;
      yield put(
        addNotification({
          message: notificationKeys.SUMMARY_REMOVE_PRODUCT_SUCCESS,
          customMessage: removeMessage,
        })
      );
      yield put(actions.removeProductSuccess(result.data.application));

      const coreServicesState = yield select(
        analyticsSelectors[EVENTS.SUMMARY_PRODUCT_REMOVE]
      );

      yield put(
        trackEvent(EVENTS.SUMMARY_PRODUCT_REMOVE, {
          ...coreServicesState,
          service_id: formatProductName(product),
        })
      );

      yield put(actions.getSummaryRequest());
    }
  } catch (error) {
    Sentry.log(error, ERRORS.UPDATE_APP_PRODUCT_REMOVAL);
    const message = `We encountered an error while removing the product. ${error}`;
    yield put(openErrorGlobalDialog(message));
    yield put(actions.removeProductFailure(error));
  }
}
export function* combinedSagas() {
  yield takeLatest(types.REMOVE_PRODUCT_REQUEST, handleRemoveProduct);
}
