import { ADD_ON_CASHBACK_CARD } from 'app/redux/modules/OrderSummary/constants';
import CashbackApi from 'redux/modules/Cashback/api';
import { OrderSummaryApi } from 'app/redux/modules/OrderSummary/api';

const transformCashback = (state) => {
  if (!CashbackApi.getHasApplied(state)) return;
  const breakdown = OrderSummaryApi.getBreakdownProduct(state, 'cashback');

  return {
    id: ADD_ON_CASHBACK_CARD,
    price: CashbackApi.getMonthlyFee(state),
    oneTimeCost: breakdown?.totalInitialCost,
  };
};

export default transformCashback;
